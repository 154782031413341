import { AutomatitCarousel } from './automatit_carousel.m';

export const initHeroCaro = () => {
	AutomatitCarousel({
		element: document.querySelector('#home_hero'),
		images: [
			{src: "/dist/images/backgrounds/img_hero_6.jpg", alt: ""},
			{src: "/dist/images/backgrounds/img_hero_2.jpg", alt: ""},
			{src: "/dist/images/backgrounds/img_hero_3.jpg", alt: ""},
			{src: "/dist/images/backgrounds/img_hero_4.jpg", alt: ""},
		],
		imagesAsBackgrounds: true,
		useChevrons: false,
		showDots: true,
	});
};

export const initVehicleBanner = () => {
	document.querySelector('#vehicle_promotion').addEventListener('click', function(e){
		location.href = e.currentTarget.querySelector('a').href;
	});
};