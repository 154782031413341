if (!Element.prototype.matches) {
	Element.prototype.matches = Element.prototype.msMatchesSelector || 
								Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
	Element.prototype.closest = function(s) {
		var el = this;

		do {
			if (Element.prototype.matches.call(el, s)) return el;
			el = el.parentElement || el.parentNode;
		} while (el !== null && el.nodeType === 1);
		return null;
	};
}

/* global Velocity */
import { getMapsHelper } from './util/mapsHelper';
import { AutomatitCarousel } from './automatit_carousel.m';

export const facilityCaro = () => {
	AutomatitCarousel({
		element: document.querySelector('#facility_carousel'),
		imagesAsBackgrounds: true,
		images: window.facCaroImgs,
		showDots: true,
		useChevrons: false
	});
};

export const facilityMap = () => {
	const mapHelper = getMapsHelper();

	mapHelper.ready()
		.then(() => {
			const theMap = mapHelper.createMap({
				element: document.querySelector('#map'),
				locationElementSelector: '#facility_info_card',
				useRichmarker: true,
				markerReducer: el => {
					return {
						lat: el.getAttribute('lat'),
						lng: el.getAttribute('lng'),
						content: `<a target="_blank" href="${el.getAttribute('link')}" class="map_pin"><img src="/dist/images/icons/map_pin.svg" alt="Visit Facility" /></a>`
					};
				}
			});

			// eslint-disable-next-line no-undef
			google.maps.event.addListenerOnce(theMap, 'idle', () => {
				theMap.setZoom(15);
			});
		});

	document.querySelector('#facility_map_toggle').addEventListener('click', () => {
		document.querySelector('#facility_map_toggle').classList.toggle('active');
		document.querySelector('#map').classList.toggle('active');
	});
};
export const facilityInfo = () => {
	document.getElementById('facility_mobile_info').addEventListener('click', function(){
		document.getElementById('facility_mobile_info').classList.toggle('active');
		document.getElementById('facility_hero').classList.toggle('active');
	});
};
export const rateFilters = () => {
	if (window.location.href.indexOf("sm") > -1) {
		document.addEventListener('DOMContentLoaded', () => {
			document.getElementById('sm_btn').click();
		});
	}
	if (window.location.href.indexOf("md") > -1) {
		document.addEventListener('DOMContentLoaded', () => {
			document.getElementById('md_btn').click();
		});
	}
	if (window.location.href.indexOf("lg") > -1) {
		document.addEventListener('DOMContentLoaded', () => {
			document.getElementById('lg_btn').click();
		});
	}
	if (window.location.href.indexOf("pk") > -1) {
		document.addEventListener('DOMContentLoaded', () => {
			document.getElementById("pk_btn").click();
		});
	}
	const filters = document.querySelectorAll('#rates_filter_wrap .filter');
	const rows = document.querySelectorAll('.rate_row');
	const noUnitsMsg = document.querySelector('.no_units_message');

	document.querySelector('#rates_filter_wrap').addEventListener('click', e => {
		const clickedFilter = e.target.closest('button');
		var unitShown = 0;

		if(clickedFilter) {
			filters.forEach(i => {
				i.removeAttribute('active');
			});
			if(clickedFilter.classList.contains('filter')) {
				clickedFilter.setAttribute('active', true);
			}

			rows.forEach(i => {
				i.setAttribute('hide', true);
				if((i.getAttribute('size') === clickedFilter.getAttribute('size')) || clickedFilter.getAttribute('size') === 'all') {
					unitShown++;
					i.removeAttribute('hide');
				}
			});
			if(unitShown === 0){
				noUnitsMsg.style.display = '';
			}else {
				noUnitsMsg.style.display = 'none';
			}
		}
	});
};
export const smoothScroll = () => {
	document.getElementById('smooth_rates').addEventListener("click", function(){
		Velocity(document.querySelector('#rates'), 'scroll');
	});
	if(document.getElementById('smooth_reviews')){
		document.getElementById('smooth_reviews').addEventListener("click", function(){
			Velocity(document.querySelector('#reviews'), 'scroll');
		});
	}
	document.getElementById('smooth_about').addEventListener("click", function(){
		Velocity(document.querySelector('#about'), 'scroll');
	});
	document.getElementById('smooth_features').addEventListener("click", function(){
		Velocity(document.querySelector('#features'), 'scroll');
	});
};